/* eslint-disable no-case-declarations */
import {
  SUPPORT_USER_LOAD,
  SUPPORT_USER_LOADED,
  SEARCH_USER_CHANGE,
  SEARCH_USER_FILTER,
  SUPPORT_USER_LOAD_BY_ORDER,
  SUPPORT_USER_ADDRESS_LOAD,
  SUPPORT_USER_ADDRESS_LOADED,
  SUPPORT_USER_ADDRESS_UPDATE,
  SUPPORT_USER_ADDRESS_UPDATED,
  SUPPORT_MODAL_SHOW_CREDIT,
  SUPPORT_MODAL_HIDE_CREDIT,
  SUPPORT_MODAL_VALIDATE_CREDIT,
  SUPPORT_MODAL_VALIDATE_CREDIT_BULK,
  SUPPORT_MODAL_SHOW_MULTIPLE_CREDIT,
  SUPPORT_MODAL_HIDE_MULTIPLE_CREDIT,
  SUPPORT_MODAL_SHOW_REFUND,
  SUPPORT_MODAL_HIDE_REFUND,
  SUPPORT_MODAL_SHOW_MULTIPLE_REFUND,
  SUPPORT_MODAL_HIDE_MULTIPLE_REFUND,
  SUPPORT_MODAL_SHOW_ORDER_REPREPARE,
  SUPPORT_MODAL_HIDE_ORDER_REPREPARE,
  SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER,
  SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER,
  SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER_LOADED,
  SUPPORT_MODAL_SHOW,
  SUPPORT_MODAL_HIDE,
  SUPPORT_MODAL_SHOW_SLOT,
  SUPPORT_SLOT_LOADED,
  SUPPORT_ORDER_ADDRESS_UPDATE,
  SUPPORT_ORDER_PREPZONE_UPDATE,
  SUPPORT_ORDER_UPDATE,
  SUPPORT_ORDER_UPDATED,
  SUPPORT_USER_UPDATE,
  SUPPORT_USER_UPDATED,
  SUPPORT_ORDER_EXTERNAL_UPDATED,
  SUPPORT_ORDER_STATUS_UPDATE,
  SUPPORT_ORDER_CRATE_UPDATED,
  SUPPORT_ORDER_STAT_DRIVER_LOADED,
  SUPPORT_STRIPESYNC_LOAD,
  SUPPORT_STRIPESYNC_LOADED,
  SUPPORT_PAYGREENSYNC_LOAD,
  SUPPORT_PAYGREENSYNC_LOADED,
  SUPPORT_DEPOSIT_USER_HISTORY_LOAD,
  SUPPORT_DEPOSIT_USER_HISTORY_LOADED,
  SUPPORT_DEPOSIT_USER_CURRENT_LOAD,
  SUPPORT_DEPOSIT_USER_CURRENT_LOADED,
  SUPPORT_ACTIVITIESLOGS_LOAD,
  SUPPORT_ACTIVITIESLOGS_LOADED,
  SUPPORT_DRIVER_LIST_LOAD,
  SUPPORT_DRIVER_LIST_LOADED,
  SUPPORT_CREDITSYNC_LOAD,
  SUPPORT_CREDITSYNC_LOADED,
  SUPPORT_MODAL_SHOW_ORDER_REDELIVER,
  SUPPORT_MODAL_HIDE_ORDER_REDELIVER,
  SUPPORT_MODAL_SHOW_ORDER_REDELIVER_LOADED,
  SUPPORT_SUBSCRIPTION_CANCELED,
  SUPPORT_USER_INTERNAL_NOTE_UPDATED,
} from "./actions";

import {
  TRANSIT_AREA_CHANGED,
  TRANSIT_AREA_LOADED,
  TROLLEY_LOADED,
  TROLLEY_LOCATION_CHANGED,
} from "../../actions/";

import {MAIN_MODAL_HIDE, MAIN_MODAL_SHOW} from "../../actions/modal";

import { TYPE_TROLLEY } from "../../utils/transit_areas";

const preCompute = (new_state) => {
  let pz_by_id = {};
  if (new_state.meta && typeof(new_state.meta.pseudoZones) !== "undefined") {
    new_state.meta.pseudoZones.forEach(pz => pz_by_id[pz.id] = pz);
  }

  if (
    typeof(new_state.order) !== "undefined"
    && new_state.order !== null
    && new_state.order.pseudo_zones_status instanceof Array
    && new_state.order.pseudo_zones_status.length > 0
  ) {
    new_state.order.pseudo_zones_status.forEach(opz => opz.pseudozone = pz_by_id[opz.pseudozone_id]);
  }

  let trolleys_by_id = {};
  if (new_state.meta && typeof(new_state.meta.trolleys) !== "undefined") {
    new_state.meta.trolleys.forEach(trolley => {
      trolleys_by_id[trolley.id] = trolley;
    });
  }
  let transit_areas_by_id = {};
  let transit_areas = new_state.transit_areas;
  if (!(new_state.transit_areas instanceof Array)) {
    transit_areas = Object.values(new_state.transit_areas);
  }
  transit_areas.forEach((transit_area) => {
    if (transit_area.transit_area_type === TYPE_TROLLEY) {
      if (transit_area.trolley in trolleys_by_id && transit_area.trolley in trolleys_by_id) {
        transit_area.trolley = trolleys_by_id[transit_area.trolley];
      }
      if (transit_area.additional_trolley && transit_area.additional_trolley in trolleys_by_id) {
        transit_area.additional_trolley = trolleys_by_id[transit_area.additional_trolley];
      }
    }
    transit_areas_by_id[transit_area.id] = transit_area;
  });
  new_state.transit_areas = transit_areas_by_id;

  return new_state;
};

const initialState = {
  query:"",
  menu: null,
  user: null,
  user_addresses: {
    loading: true,
    shouldRefresh: false,
    datas: []
  },
  order_list: [],
  SMS_list: [],
  subscription_list: [],
  subvention_logs: [],
  stripe: {
    loading: true,
    shouldRefresh: false,
    datas: []
  },
  paygreen: {
    loading: true,
    shouldRefresh: false,
    datas: []
  },
  credit: {
    loading: true,
    shouldRefresh: false,
    datas: []
  },
  deposit_user: {
    history: {
      loading: true,
      datas: null,
    },
    current: {
      loading: true,
      datas: null,
    }
  },
  activitieslogs: {
    loading: true,
    datas: []
  },
  drivers: {
    loading: false,
    datas: []
  },
  order: null,
  meta: {
    sub_type_list: [],
    order_product_status: {},
    transit_area_types: {},
    order_prepare_status: [],
    crossdock_status_transitions: {},
    all_centers: [],
    trolleys: [],
    trolley_locations: [],
  },
  transit_areas: {},
  modalCredit:{},
  modalMultipleCredit:{},
  modalRefund:{},
  modalMultipleRefund:{},
  modalRedeliver:{},
  modalReprepare:{},
  modalReprepareAfter:{},
  modal:{},
  updating:{},
  order_stat_driver: {
    loading: true,
    datas: null
  },
};

export const supportReducer = function(state = initialState, action) {

  switch (action.type) {
  case SUPPORT_USER_LOAD:
    return {
      ...state,
      menu: null,
      query: '',
      order: null,
      user: {id: action.id, email: action.email},
      loading: true,
      stripe: {loading: true, shouldRefresh: false, datas: []},
      deposit_user: {history: {datas: null}, current: {datas:null}},
    };
  case SUPPORT_USER_LOAD_BY_ORDER:
    return {...state, menu: null, query: '', order: null, loading: true};

  case SUPPORT_USER_LOADED:

    return preCompute({
      ...state,
      order: {
        ...action.order,
        amount: Math.floor(action.order.amount * 100) / 100
      },
      order_list: action.order_list,
      SMS_list: action.SMS_list,
      subscription_list: action.subscription_list,
      subvention_logs: action.subvention_logs,
      user: action.user,
      meta: action.meta,
      transit_areas: action.transit_areas,
      loading: false,
      stripe: {...state.stripe, shouldRefresh: true},
      credit: {...state.credit, shouldRefresh: true},
      paygreen: {...state.paygreen, shouldRefresh: true},
      deposit_user: {...state.deposit_user, shouldRefresh: true},
    });
  case SUPPORT_USER_ADDRESS_LOAD:
    return preCompute({
      ...state,
      user_addresses: {
        loading: true,
        datas: []
      }
    });

  case SUPPORT_USER_ADDRESS_LOADED:
    return preCompute({
      ...state,
      user_addresses: {
        ...state.user_addresses,
        loading: false,
        datas: action.data.data
      }
    });

  case SUPPORT_USER_ADDRESS_UPDATE:
    return preCompute({
      ...state,
      user_addresses: {
        ...state.user_addresses,
        loading: true,
      }
    });
  case SUPPORT_USER_ADDRESS_UPDATED:
    return preCompute({
      ...state,
      user_addresses: {
        ...state.user_addresses,
        shouldRefresh: true,
      }
    });
  case SUPPORT_USER_INTERNAL_NOTE_UPDATED:
    return preCompute({ 
      ...state,
      user: {
        ...state.user,
        internal_note: { ...action.internalNote }
      }
    });
  case SEARCH_USER_FILTER:
    return {...state, menu: action.value};
  case SEARCH_USER_CHANGE:
    return {...state, query: action.value.query};

  case SUPPORT_MODAL_SHOW_CREDIT: {
    let modalData = {...action, show: true};
    delete modalData.type;
    return {...state, modalCredit: modalData};
  }

  case SUPPORT_MODAL_VALIDATE_CREDIT: {
    let order = state.order;
    if (action.field === "order_product_id") {
      order = {
        ...state.order,
        products: state.order.products.map(op => {
          if (action.id === op.id) {
            return {...op, updating: true};
          }
          return op;
        })
      };
    }
    if (action.field === "order_id") {
      order = {...order, updating: true};
    }
    return preCompute({...state, order: order, modalCredit: {}});
  }

  case SUPPORT_MODAL_VALIDATE_CREDIT_BULK: {
    let order = state.order;
    order = {...order, updating: true};
    return preCompute({...state, order: order, modalMultipleCredit: {}});
  }

  case SUPPORT_MODAL_HIDE_CREDIT:
    return {...state, modalCredit: {}};

  case SUPPORT_MODAL_SHOW_MULTIPLE_CREDIT: {
    let modalData = {...action, show: true};
    delete modalData.type;
    return {...state, modalMultipleCredit: modalData};
  }

  case SUPPORT_MODAL_HIDE_MULTIPLE_CREDIT:
    return {...state, modalMultipleCredit: {}};

  case SUPPORT_MODAL_SHOW_REFUND: {
    let modalData = {...action, show: true};
    delete modalData.type;
    return {...state, modalRefund: modalData};
  }

  case SUPPORT_MODAL_HIDE_REFUND:
    return {...state, modalRefund: {}};

  case SUPPORT_MODAL_SHOW_MULTIPLE_REFUND: {
    let modalData = {...action, show: true};
    delete modalData.type;
    return {...state, modalMultipleRefund: modalData};
  }

  case SUPPORT_MODAL_HIDE_MULTIPLE_REFUND:
    return {...state, modalMultipleRefund: {}};

  case SUPPORT_MODAL_SHOW_ORDER_REDELIVER:
    let deliverData = {...action, show: true};
    delete deliverData.type;
    return {...state, modalRedeliver: deliverData};

  case SUPPORT_MODAL_SHOW_ORDER_REDELIVER_LOADED:
    let deliverDataLoaded = {...action, show: true};
    delete deliverDataLoaded.type;
    return {...state, modalRedeliver: deliverDataLoaded};

  case SUPPORT_MODAL_HIDE_ORDER_REDELIVER:
    return {...state, modalRedeliver: {}};

  case SUPPORT_MODAL_SHOW_ORDER_REPREPARE:
    let modalData = {...action, show: true};
    delete modalData.type;
    return {...state, modalReprepare: modalData};

  case SUPPORT_MODAL_HIDE_ORDER_REPREPARE:
    return {...state, modalReprepare: {}};

  case SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER:
    return {...state, modal: action.modalData};

  case SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER_LOADED:
    let modalData2 = {...action, show: true};
    delete modalData2.type;
    return {...state, modalReprepareAfter: modalData2};


  case SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER:
    return {...state, modalReprepareAfter: {}};

  case SUPPORT_MODAL_SHOW:
  case SUPPORT_MODAL_SHOW_SLOT:
    return {...state, modal: action.modalData};

  case SUPPORT_MODAL_HIDE:
    return {...state, modal: {}};

  case SUPPORT_SLOT_LOADED:
    return {...state, modal: {...state.modal, slots: action.data}};

  case SUPPORT_ORDER_UPDATE:
  case SUPPORT_ORDER_PREPZONE_UPDATE:
  case SUPPORT_ORDER_ADDRESS_UPDATE: {
    let order = {...state.order, updating: true};
    return preCompute({...state, order: order});
  }

  case SUPPORT_ORDER_CRATE_UPDATED:
    return preCompute({
      ...state,
      order: {...state.order, crates: action.crates },
      modal: {...state.modal, order: {...state.modal.order, crates: action.crates }}
    });

  case SUPPORT_ORDER_UPDATED: {
    let user = state.user;
    let order_list = state.order_list;
    if (order_list) {
      order_list = order_list.map(o => {
        if (o.id === action.data.id) {
          return {...o, shipping_time: action.data.shipping_time, max_shipping_time: action.data.max_shipping_time};
        }
        return o;
      });
    }

    return preCompute({...state, order: action.data, user: user, order_list: order_list});
  }

  case SUPPORT_ORDER_STAT_DRIVER_LOADED:
    return preCompute({
      ...state,
      order_stat_driver: {
        loading: false,
        datas: action.data
      }
    });

  case SUPPORT_USER_UPDATE: {
    let user = {...state.user, updating: true};
    return {...state, user: user};
  }

  case SUPPORT_USER_UPDATED: {
    return {...state, user: action.user};
  }

  case SUPPORT_ORDER_EXTERNAL_UPDATED: {
    return preCompute({...state, order: {...state.order, ...action.data, updating: false}});
  }

  case SUPPORT_ORDER_STATUS_UPDATE: {
    return preCompute({...state, order: {...state.order, updating: true}});
  }

  case SUPPORT_STRIPESYNC_LOAD: {
    return {...state, stripe: {...state.stripe, loading: true, shouldRefresh: false}};
  }

  case SUPPORT_STRIPESYNC_LOADED: {
    return {...state, stripe: {datas: action.stripe_datas, loading: false, shouldRefresh: false}};
  }

  case SUPPORT_PAYGREENSYNC_LOAD: {
    return {...state, paygreen: {...state.paygreen, loading: true, shouldRefresh: false}};
  }

  case SUPPORT_PAYGREENSYNC_LOADED: {
    return {...state, paygreen: {datas: action.datas, loading: false, shouldRefresh: false}};
  }

  case SUPPORT_CREDITSYNC_LOAD: {
    return {...state, credit: {...state.credit, loading: true, shouldRefresh: false}};
  }

  case SUPPORT_CREDITSYNC_LOADED: {
    return {...state, credit: {datas:action.credit_datas, loading: false, shouldRefresh: false}};
  }

  case SUPPORT_DEPOSIT_USER_HISTORY_LOAD:
    return {...state, deposit_user: {...state.deposit_user, shouldRefresh: false, history: {...state.deposit_user.history, loading: true}}};
  case SUPPORT_DEPOSIT_USER_HISTORY_LOADED:
    return {...state, deposit_user: {...state.deposit_user, shouldRefresh: false, history: {datas: action.history, loading: false}}};

  case SUPPORT_DEPOSIT_USER_CURRENT_LOAD:
    return {...state, deposit_user: {...state.deposit_user, shouldRefresh: false, current: {...state.deposit_user.current, loading: true}}};
  case SUPPORT_DEPOSIT_USER_CURRENT_LOADED:
    return {...state, deposit_user: {...state.deposit_user, shouldRefresh: false, current: {datas: action.current, loading: false}}};

  case SUPPORT_ACTIVITIESLOGS_LOAD: {
    return {...state, activitieslogs: {...state.activitieslogs, loading: true}};
  }
  case SUPPORT_ACTIVITIESLOGS_LOADED: {
    return {...state, activitieslogs: {datas: action.activities_data, loading: false}};
  }

  case SUPPORT_DRIVER_LIST_LOAD: {
    return {...state, drivers: {...state.drivers, loading: true}};
  }
  case SUPPORT_DRIVER_LIST_LOADED: {
    return {...state, drivers: {datas: Object.values(action), loading: false}};
  }

  case TRANSIT_AREA_CHANGED: {
    return preCompute({
      ...state,
      order: {
        ...state.order,
        transit_area: action.transit_area,
        transit_area_current: action.transit_area_current,
        transit_area_target_type: action.transit_area_target_type,
        pseudo_zones_status: action.pseudo_zones_status,
      }
    });
  }

  case TRANSIT_AREA_LOADED:
    return preCompute({...state, transit_areas: action.transit_areas });

  case TROLLEY_LOADED:
    return preCompute({
      ...state,
      meta: {
        ...state.meta,
        trolleys: action.results
      }
    });

  case TROLLEY_LOCATION_CHANGED:
    let transit_areas = [];
    let trolley_ta_by_id = {};
    if (!(state.transit_areas instanceof Array)) {
      transit_areas = Object.values(state.transit_areas);
    }
    transit_areas.forEach((ta) => {
      if (
        ta.transit_area_type === TYPE_TROLLEY
        && (
          (typeof(ta.trolley.id) !== "undefined" && ta.trolley.id === action.id)
          || (ta.trolley === action.id)
        )
      ) {
        ta.trolley = action;
      }
      trolley_ta_by_id[ta.id] = ta;
    });

    return preCompute({
      ...state,
      transit_areas: trolley_ta_by_id
    });

  case SUPPORT_SUBSCRIPTION_CANCELED: {
    let subscription_list = state.subscription_list.map(
      (subscription) => {
        if(subscription.id === action.subscriptionId){
          return Object.assign(
            {},
            subscription,
            { status: "canceled" }
          );
        }
        return subscription;
      }
    );
    return {...state, subscription_list: subscription_list};
  }

  case MAIN_MODAL_SHOW:
    return {...state, modal : action.modalData};
  case MAIN_MODAL_HIDE:
    return {...state, modal : {}};

  default: {
    return state;
  }
  }
};
