
/* eslint-disable react/prop-types */
import React from "react";
import {Link, withRouter} from "react-router-dom";
import { Alert } from "react-bootstrap";
import {connect} from "react-redux";
import DocumentTitle from "react-document-title";
import moment from "moment";

import "../css/UserOrder.css";
import {
  loadOrder,
  act_loadStripeSync,
  loadUser,
  act_loadDepositUserHistory,
  act_loadDepositUserCurrent,
  act_loadCreditSync,
  act_loadPaygreenSync,
  SUPPORT_USER_EDIT_INTERNAL_NOTE,
} from "../actions";
import {OrderDetail} from "./OrderDetail";
import {OrderList} from "./UserTabs/OrdersList";
import {SubventionLogs} from "./UserTabs/SubventionLogs";
import {SMSList} from "./UserTabs/SMSList";
import {StripeList} from "./UserTabs/StripeList";
import {PaygreenList} from "./UserTabs/PaygreenList";
import {DangerZone} from "./UserTabs/DangerZone";
import {InvoicesZone} from "./UserTabs/Invoices";
import {DepositUser} from "./UserTabs/Deposit/DepositUser";
import {CreditModal} from "./CreditModal";
import {MultipleCreditModal} from "./MultipleCreditModal";
import {ReprepareModal} from "./Order/Reprepare/ReprepareModal";
import {ReprepareAfterModal} from "./Order/Reprepare/ReprepareAfterModal";
import {SlotModal} from "./SlotModal";
import CrateModalLinked from "../containers/CrateModalLinked";
import {AddressModal} from "./AddressModal";
import {OrderPrepZoneNbBagsModal} from "./Order/PrepZone/OrderPrepZoneNbBagsModal";
import {ChangeNumberOfBagsModal} from "./modal/ChangeNumberOfBagsModal";
import {RefundModal} from "./RefundModal";
import {MultipleRefundModal} from "./MultipleRefundModal";
import {RefundOrderModal} from "./Order/modals/RefundOrderModal";
import {ChangeStatusModal} from "./ChangeStatusModal";
import {ConfirmModal} from "./ConfirmModal";
import {GiftMessageModal} from "./GiftMessageModal";
import {Badge, Tab, Tabs} from "react-bootstrap";
import {UserForm} from "./UserTabs/UserForm";
import {UserAddresses} from "./UserTabs/UserAddresses";
import {ChangeDeliveryNameModal} from "./ChangeDeliveryNameModal";
import {ChangeDeliveryPhoneModal} from "./ChangeDeliveryPhoneModal";
import {setCenterId} from "../../../utils/center";
import { TransitAreaModal } from "./modal/TransitAreaModal";
import { ChangeTrolleyLocationModal } from "./modal/ChangeTrolleyLocationModal";
import {CreditList} from "./UserTabs/CreditList";
import {ModalStatDriver} from "./Order/Delivery/ModalStatDriver";
import {RedeliverModal} from "./Order/Redeliver/RedeliverModal";
import { isSuperAdmin, isSupport } from '../../../utils/permissions';
import {CommonModals} from "../../../common-comps/CommonModals";
import UserInternalNote from "./UserInternalNote";


// component
class InternalUserOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddresses: false,
      showCredits: false,
      showDeposit: false,
      showPaygreen: false,
    };
    this.handleTabSelect = this.handleTabSelect.bind(this);  // Bind handler
  }

  // Handle tab selection
  handleTabSelect(selectedTab) {
    if (selectedTab === 'user_addresses') {
      this.setState({ showAddresses: true });
    }
    if (selectedTab === 'deposit') {
      this.setState({ showDeposit: true });
    }
    if (selectedTab === 'credit') {
      this.setState({ showCredits: true });
    }
    if (selectedTab === 'stripe') {
      this.setState({ showStripe: true });
    }
    if (selectedTab === 'paygreen') {
      this.setState({ showPaygreen: true });
    }
  }

  /**
   * centralise le matching + loading du coposant
   * @param params
   * @private
   */
  _matchMe(params) {
    if (params.center_id && !isNaN(parseInt(params.center_id, 10))) {
      setCenterId(parseInt(params.center_id, 10));
    }
    if (params.user_id) {
      this.props.loadByUser(this.props.match.params.user_id, this.props.match.params.email);
    }
    if (params.order_id) {
      this.props.loadByOrder(this.props.match.params.order_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {

      this._matchMe(this.props.match.params);
    }
  }

  componentDidMount() {
    this._matchMe(this.props.match.params);
  }

  render() {
    let { user, order, order_list, stripe, SMS_list, deposit_user, allCenters, sites, user_me, credit, subvention_logs, paygreen } = this.props;
    const { showAddresses, showCredits, showDeposit, showStripe, showPaygreen } = this.state;

    const userLoaded = user && user.date_joined;
    let centerName = 'inconnu';
    if (userLoaded && order) {
      let usersite = user.usersite.find(x => x.site === order.site_id);
      if (usersite) {
        let center = allCenters.find(x => x.id === usersite.center);
        centerName = center ? center.name : "??";
      }
    }

    let siteName = 'Site inconnu';
    let siteColor = 'grey';
    if (order && typeof(sites) !== 'undefined') {
      if (typeof(sites[order.site_id]) !== 'undefined') {
        siteName = sites[order.site_id].domain;
        siteColor = sites[order.site_id].color;
      }
    }

    return (
      <div className="UserOrder">
        <div className="row">
          <div className="col-md-7">
            <DocumentTitle title={userLoaded ? `${user.email}` : "loading"}>
              <h3>
                {userLoaded ? <span className="user-name">{user.first_name} {user.last_name}</span> : <span className="loading">Loading...</span>}
                - {user ? <a href={process.env.REACT_APP_DELEEVADMIN + `/users/${user.id}`} target="_blank" rel="noopener noreferrer" >{user.email}  </a> : null}
                {userLoaded && user.frichti_user
                  && (
                    <a
                      href={process.env.REACT_APP_DELEEVADMIN + `/old_frichti/customers/${user.frichti_user.id}/orders`}
                      title="Anciennes commandes Frichti"
                      style={{margin: "auto 1rem !important;", color: "gray"}}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-regular fa-floppy-disk"></i>
                    </a>
                  )
                }
                {userLoaded ? <span> - <span className="user-phone-number">{user.phone_number}</span></span> : null}
                {userLoaded && user.is_fraud ? <span className="label label-warning">FRAUD</span> : null}
                {userLoaded && user.credits ? <span className="label label-default"> - Crédits: {user.credits}€</span> : <span> - Aucun crédit</span>}
              </h3>
            </DocumentTitle>
            {userLoaded && user.super_venere_at &&
              <h4 className="subtitle">
                <Badge variant="danger">
                  Super vénère depuis le {moment(user.super_venere_at).format('DD/MM/YYYY')}
                </Badge>
              </h4>
            }
            {userLoaded && user.referrer_info ?
              <h6 className="subtitle">
                <b>Parrain : </b>
                <Link to={`/support/user/${user.referrer_info.id}/${user.referrer_info.email}`} >
                  {user.referrer_info.first_name} {user.referrer_info.last_name} - {user.referrer_info.email}
                </Link>
              </h6> : null}
            {order && order.site_id > 0 ?
              <h4 className="subtitle">
                <Badge style={{backgroundColor: siteColor}}>{siteName}</Badge>
              </h4> : null}
            {userLoaded && centerName ?
              <h6 className="subtitle">
                <b>Centre forcé : </b>
                {centerName}
              </h6> : null}
            {userLoaded && !user.is_multicenter_enabled ?
              <h6 className="subtitle">
                <b>Ce client n'utilise pas encore une version de l'app multi-centre.</b>
              </h6> : null}
          </div>
          {userLoaded && user.internal_note
            ? <UserInternalNote user={user} editNote={this.props.editInternalNote} /> : null
          }
        </div>
        <div className="row">
          <div className="col-md-7">
            {order && order.id ? <OrderDetail order={order} user={user}/> : null}
          </div>
          <div className="col-md-5">
            <Tabs defaultActiveKey='order_list' id="NavUser" transition={false} onSelect={this.handleTabSelect}>
              <Tab eventKey='order_list' title="Commandes">
                <OrderList orderList={order_list} />
              </Tab>
              <Tab eventKey='user' title="Client">
                <UserForm user={user}/>
              </Tab>
              <Tab eventKey='user_addresses' title="Adresses">
                {showAddresses && <UserAddresses user={user} />}
              </Tab>
              <Tab eventKey='stripe' title="CB">
                {showStripe && <StripeList stripe={stripe} user={user} />}
              </Tab>
              <Tab eventKey='paygreen' title="Paygreen">
                {showPaygreen && <PaygreenList paygreen={paygreen} user={user} />}
              </Tab>
              <Tab eventKey='credit' title="Crédits">
                {showCredits && <CreditList credit={credit} user={user} />}
              </Tab>
              <Tab eventKey='SMS_list' title="SMS">
                <SMSList SMSList={SMS_list} />
              </Tab>
              <Tab eventKey='deposit' title="Consignes">
                {showDeposit && <DepositUser deposit_user={deposit_user} user={user} />}
              </Tab>
              <Tab eventKey='subvention_logs' title="Usage des subs">
                <SubventionLogs subventionLogs={subvention_logs} />
              </Tab>
              { (isSuperAdmin(user_me) || isSupport(user_me)) &&
                <Tab eventKey='divers' title="Divers">
                  <div className="mt-2">
                    <h3 className="text-left border-bottom py-1 h4">Factures</h3>
                    <InvoicesZone user={user} />
                    <h3 className="text-left  border-bottom py-1 mt-3 h4">Danger</h3>
                    <DangerZone user={user} />
                  </div>
                </Tab>
              }
            </Tabs>
          </div>
        </div>
        <CreditModal />
        <MultipleCreditModal />
        <ReprepareModal />
        <ReprepareAfterModal />
        <RedeliverModal />
        <SlotModal />
        <AddressModal />
        <OrderPrepZoneNbBagsModal />
        <ChangeNumberOfBagsModal />
        <RefundModal />
        <MultipleRefundModal />
        <RefundOrderModal />
        <ChangeStatusModal />
        <ChangeDeliveryNameModal />
        <ChangeDeliveryPhoneModal />
        <ConfirmModal />
        <GiftMessageModal />
        <TransitAreaModal />
        <ChangeTrolleyLocationModal />
        <CrateModalLinked />
        <ModalStatDriver />
        <CommonModals />
      </div>
    );
  }
}


// link
export const UserOrder = withRouter(connect(
  (state) => {
    return {
      order : state.support.order,
      user : state.support.user,
      order_list : state.support.order_list,
      stripe: state.support.stripe,
      credit: state.support.credit,
      SMS_list : state.support.SMS_list,
      deposit_user: state.support.deposit_user,
      allCenters: state.support.meta ? state.support.meta.all_centers : [],
      sites: state.support.meta.sites,
      user_me: state.user,
      subscription_list : state.support.subscription_list,
      subvention_logs: state.support.subvention_logs,
      paygreen: state.support.paygreen,
    };
  },
  (dispatch) => {

    return {
      loadByUser : (id, email) => {
        return dispatch(loadUser(id, email));
      },
      loadByOrder: (order_id) => {
        //console.log("loadByOrder", order_id);
        return dispatch(loadOrder(order_id));
      },
      loadStripeSync: (id) => {
        return dispatch(act_loadStripeSync(id));
      },
      loadPaygreenSync: (id) => {
        return dispatch(act_loadPaygreenSync(id));
      },
      loadCreditSync: (id) => {
        return dispatch(act_loadCreditSync(id));
      },
      loadDepositUserHistory: (id) => {
        return dispatch(act_loadDepositUserHistory(id));
      },
      loadDepositUserCurrent: (id) => {
        return dispatch(act_loadDepositUserCurrent(id));
      },
      editInternalNote: (id, content) => dispatch({ type: SUPPORT_USER_EDIT_INTERNAL_NOTE, id, content}),
    };
  }
)(InternalUserOrder));
