import { call, put, takeEvery} from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import {api, da, daUtil} from "../../api";
import {
  SUPPORT_MODAL_SHOW_SLOT,
  SUPPORT_MODAL_HIDE_REFUND,
  SUPPORT_MODAL_VALIDATE_CREDIT,
  SUPPORT_MODAL_VALIDATE_CREDIT_BULK,
  SUPPORT_MODAL_VALIDATE_CHANGE_STATUS,
  SUPPORT_ORDER_ADDRESS_UPDATE,
  SUPPORT_ORDER_REFUND,
  SUPPORT_ORDER_PREPZONE_UPDATE,
  SUPPORT_ORDER_UPDATE,
  SUPPORT_ORDER_UPDATED,
  SUPPORT_ORDER_CRATE_ADD,
  SUPPORT_ORDER_CRATE_DELETE,
  SUPPORT_ORDER_CRATE_UPDATED,
  SUPPORT_ORDER_STAT_DRIVER_LOADED,
  SUPPORT_SLOT_LOADED,
  SUPPORT_USER_LOAD,
  SUPPORT_USER_LOAD_BY_ORDER,
  SUPPORT_USER_LOADED,
  SUPPORT_USER_UPDATE,
  SUPPORT_USER_UPDATED,
  SUPPORT_USER_ADDRESS_LOAD,
  SUPPORT_USER_ADDRESS_LOADED,
  SUPPORT_USER_ADDRESS_UPDATE,
  SUPPORT_USER_ADDRESS_UPDATED,
  SUPPORT_ORDER_GIFT_MESSAGE,
  SUPPORT_INVOICE_DOWNLOAD,
  SUPPORT_INVOICES_DOWNLOAD_BY_YEAR,
  SUPPORT_ORDER_EXTERNAL_UPDATED,
  SUPPORT_ORDER_STATUS_UPDATE,
  SUPPORT_STRIPESYNC_LOAD,
  SUPPORT_STRIPESYNC_LOADED,
  SUPPORT_PAYGREENSYNC_LOAD,
  SUPPORT_PAYGREENSYNC_LOADED,
  SUPPORT_CREDITSYNC_LOAD,
  SUPPORT_CREDITSYNC_LOADED,
  SUPPORT_DEPOSIT_RELOAD,
  SUPPORT_DEPOSIT_USER_HISTORY_LOAD,
  SUPPORT_DEPOSIT_USER_HISTORY_LOADED,
  SUPPORT_DEPOSIT_USER_CURRENT_LOAD,
  SUPPORT_DEPOSIT_USER_CURRENT_LOADED,
  SUPPORT_DEPOSIT_USER_CREATE_RECEIVED,
  SUPPORT_ACTIVITIESLOGS_LOAD,
  SUPPORT_ACTIVITIESLOGS_LOADED,
  SUPPORT_DRIVER_LIST_LOAD,
  SUPPORT_DRIVER_LIST_LOADED,

  showPopinReprepareOrder,
  SUPPORT_MODAL_SHOW_ORDER_REPREPARE_BEFORE,
  SUPPORT_MODAL_HIDE_ORDER_REPREPARE,
  SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER,
  SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER_LOADED,
  SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER,
  SUPPORT_MODAL_SUBMIT_ORDER_REPREPARE,
  SUPPORT_DELETE_ACCOUNT,
  SUPPORT_FIX_CENTERS,
  SUPPORT_DISCONNECT_EDENRED,
  SUPPORT_RECREDIT_SHIPPING_FEE,
  SUPPORT_REFUND_SHIPPING_FEE,
  SUPPORT_MODAL_SHOW,
  SUPPORT_MODAL_SHOW_ORDER_REDELIVER,
  SUPPORT_MODAL_SHOW_ORDER_REDELIVER_LOADED,
  SUPPORT_MODAL_SUBMIT_ORDER_REDELIVER,
  SUPPORT_MODAL_HIDE_ORDER_REDELIVER,
  SUPPORT_CANCEL_SUBSCRIPTION,
  SUPPORT_SUBSCRIPTION_CANCELED,
  SUPPORT_CANCEL_CREDIT,
  SUPPORT_CANCEL_PAYGREEN_TRANSACTION,
  SUPPORT_USER_EDIT_INTERNAL_NOTE,
  SUPPORT_USER_INTERNAL_NOTE_UPDATED,
  act_loadCreditSync,
  act_loadPaygreenSync,
} from "./actions";
import { errorThrown } from '../../actions';

import {TRANSIT_AREA_LOAD} from "../../actions";

import * as Raven from "raven-js";
import {extractApiErrorMessageFromError} from "../../utils";

function* changeStatusViaSupport(action) {
  const orderId = action.orderId;

  try {
    // network calls
    const res = yield call(
      api.changeOrderStatus,
      orderId,
      action.newStatus,
      "flat",
      action.additionalData,
    );
    // to update support order
    yield put({type: SUPPORT_ORDER_EXTERNAL_UPDATED, data: res});
    yield put(Notifications.success({title: "Commande" , "message": "Le statut a été mis à jour avec succès",  autoDismiss:3, dismissible:"click"}));

  } catch (e) {
    let message = extractApiErrorMessageFromError(e);
    if(message !== null){
      yield put(
        Notifications.error(
          {title: "Commande" , "message": message,  autoDismiss: 3, dismissible: "click"}
        )
      );
    }
    else{
      yield put(errorThrown(e));
      Raven.captureException(e);
    }
  }
}

function* loadSupportByUser(action) {
  const res = yield call(daUtil.get, `/support-info/?user_id=${action.user_id}`);
  yield put({...res, type: SUPPORT_USER_LOADED});
}

function* updateUser(action) {
  const res = yield call(daUtil.patch, `/users/${action.userId}/`, action.patch);
  yield put({user: res, type: SUPPORT_USER_UPDATED});
  yield put(Notifications.success({title: "Fiche client" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));
}

function* loadSupportByOrder(action) {
  const res = yield call(daUtil.get, `/support-info/?order_id=${action.order_id}`);
  yield put({...res, type: SUPPORT_USER_LOADED});
}

function* addCredits(action) {
  let data = {
    'amount': action.creditAmount,
    'subtype': action.subtype,
    'notes': action.notes,
    'quantity': action.quantity
  };
  data[action.field] = action.id;
  const res =  yield call(daUtil.post, `/processing-orders/add_credits/?ser=admin`, data);
  if (action.refreshUserId) {
    const resUser = yield call(daUtil.get, `/users/${action.refreshUserId}/`);
    yield put({type: SUPPORT_USER_UPDATED, user: resUser });
  }
  yield put({type: SUPPORT_ORDER_UPDATED, data: res});
  if (action.callback) {
    action.callback();
  }
}

function* addCreditsBulk(action) {
  delete action['type'];

  console.debug(JSON.stringify(action, null, 1));
  const res =  yield call(daUtil.post,
    `/processing-orders/add_credits_bulk/?ser=admin`,
    action);

  if (action.refreshUserId) {
    const resUser = yield call(daUtil.get, `/users/${action.refreshUserId}/`);
    yield put({type: SUPPORT_USER_UPDATED, user: resUser });
  }

  yield put({type: SUPPORT_ORDER_UPDATED, data: res});
}

function* cancelPaygreenTransaction(action) {
  try {
    yield call(daUtil.patch, `/paygreen/${action.paymentOrderId}/cancel-transaction/`,  {userId: action.userId});
    yield put(Notifications.success({title: "Transaction" , "message": "La transaction a été annulée",  autoDismiss:3, dismissible:"click"}));
    yield put(act_loadPaygreenSync(action.userId));
  } catch (e) {
    let message = extractApiErrorMessageFromError(e);
    if(message !== null){
      yield put(
        Notifications.error(
          {title: "Transaction" , "message": message,  autoDismiss: 3, dismissible: "click"}
        )
      );
    }
  }
}

function* cancelCredit(action) {
  let data = {
    "log_money_id": action.logMoneyId,
    "notes": action.notes,
  };
  try {
    // network calls
    yield call(daUtil.post, `/processing-orders/cancel_credits/`, data);
    const res2 = yield call(daUtil.get, `/processing-orders/${action.orderId}/?ser=admin`);
    // to update support order
    yield put({type: SUPPORT_ORDER_UPDATED, data: res2});
    yield put(
      Notifications.success(
        {
          title: "Commande",
          message: "Le crédit a été annulé.",
          autoDismiss: 3,
          dismissible: "click",
        }
      )
    );
    const resUser = yield call(daUtil.get, `/users/${action.refreshUserId}/`);
    yield put({type: SUPPORT_USER_UPDATED, user: resUser });
    yield put(act_loadCreditSync(action.refreshUserId));
  } catch (e) {
    let message = e.message;
    if (e.response && e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0];
    }
    yield put(
      Notifications.error(
        {
          title: "Une erreur est survenue",
          message: message,
          autoDismiss: 5,
          dismissible: "click",
        }
      )
    );
    Raven.captureException(e);
  }
}


function* recreditShippingFee(action) {
  let data = {
    'notes': action.notes
  };
  data[action.field] = action.id;
  try {
    // network calls
    const res =  yield call(daUtil.post, `/processing-orders/recredit_shipping_fee/?ser=admin`, data);
    // to update support order
    yield put({type: SUPPORT_ORDER_UPDATED, data: res});
    yield put(Notifications.success({title: "Commande" , "message": "Les frais de livraison ont été recrédités.",  autoDismiss:3, dismissible:"click"}));
  } catch (e) {
    let message = e.message;
    if (e.response && e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0];
    }
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }

}


function* refundShippingFee(action) {
  let data = {
    'notes': action.notes
  };
  data[action.field] = action.id;
  try {
    // network calls
    const res =  yield call(daUtil.post, `/processing-orders/refund_shipping_fee/?ser=admin`, data);
    // to update support order
    yield put({type: SUPPORT_ORDER_UPDATED, data: res});
    yield put(Notifications.success({title: "Commande" , "message": "Les frais de livraison ont été remboursés.",  autoDismiss:3, dismissible:"click"}));
  } catch (e) {
    let message = e.message;
    if (e.response && e.response.data && e.response.data.errors) {
      message = e.response.data.errors[0];
    }
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* loadSlot(action) {
  const res =  yield call(daUtil.get, `/postal_codes/slots/?postal_code=${action.modalData.postalCode}&order_id=${action.modalData.orderId}`);
  yield put({type: SUPPORT_SLOT_LOADED, data: res});
}

function* loadReprepareBefore(action) {
  if (action.order.products.length === 0) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": "Aucun produit trouvé",  autoDismiss:5, dismissible:"click"}));
  } else {
    let product_ids = [];
    Array.prototype.forEach.call(action.order.products, product => {
      product_ids.push(product.product.id);
    });
    try {
      console.log("API", process.env.REACT_APP_DELEEVAPI);

      // Need to filter with center on front because can't on api
      let res = yield call(daUtil.get, `/admin-products/product-centers/?center_ids=${action.order.center}&limit=200&offset=0&product_ids=${product_ids.join(",")}`);
      let products_details = res.results;
      while (res.next !== null) {
        res = yield call(daUtil.get, res.next);
        products_details = products_details.concat(res.results);
      }
      yield put(showPopinReprepareOrder(action.order, products_details));
    } catch (e) {
      yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
      Raven.captureException(e);
    }
  }
}

function *loadRedeliver(action) {
  const res = yield call(daUtil.get, `/postal_codes/slots/?postal_code=${action.postalCode}&order_id=${action.order.id}`);
  const resAddress = yield call(daUtil.get, `/admin-addresses/?user_id=${action.order.user}`);
  yield put({type: SUPPORT_MODAL_SHOW_ORDER_REDELIVER_LOADED, address: action.address, addresses: {loading: false, datas: resAddress.data}, data: res, order: action.order, label: `Commande #${action.order.random_id} nouvelle livraison`});
}

function* loadReprepareAfter(action) {
  let checked_ctr = 0;
  let error = false;
  let productsWithNegativeQuantity = '';
  let productsWithOverQuantity = '';
  Array.prototype.forEach.call(action.order.products, product => {
    if (product.checked){
      ++checked_ctr;
    }
    if(product.reprepQuantity < 0){
      productsWithNegativeQuantity += " " +product.product.selling_name;
    }
    if(product.reprepQuantity > product.quantity){
      productsWithOverQuantity += " " +product.product.selling_name;
    }
  });

  if(productsWithNegativeQuantity !== '') {
    yield put(Notifications.error({
      title: "Une erreur est survenue",
      "message": "La quantité du(es) produit(s) " + productsWithNegativeQuantity + " est négative.",
      autoDismiss: 5,
      dismissible: "click"
    }));
    error = true;
  }
  if(productsWithOverQuantity !== '') {
    yield put(Notifications.error({
      title: "Une erreur est survenue",
      "message": "La quantité du(es) produit(s) " + productsWithOverQuantity + " est supérieure à la quantité commandée.",
      autoDismiss: 5,
      dismissible: "click"
    }));
    error = true;
  }
  if (checked_ctr === 0){
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": "Aucun produit sélectionné",  autoDismiss:5, dismissible:"click"}));
    error = true;
  }
  if(!error){
    const res = yield call(daUtil.get, `/postal_codes/slots/?postal_code=${action.modalData.postalCode}&order_id=${action.modalData.orderId}`);
    const resAddress = yield call(daUtil.get, `/admin-addresses/?user_id=${action.order.user}`);
    yield put({type: SUPPORT_USER_ADDRESS_LOADED, data: resAddress});

    yield put({type: SUPPORT_MODAL_HIDE_ORDER_REPREPARE});
    yield put({type: SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER_LOADED, data: res, order: action.order, label: `Commande #${action.order.random_id} repréparation étape 2`});
  }
}

function* loadUserAddresses(action) {
  if(!action.user_id > 0){
    return;
  }
  try {
    const res = yield call(daUtil.get, `/admin-addresses/?user_id=${action.user_id}`);
    yield put({type: SUPPORT_USER_ADDRESS_LOADED, data: res});
  } catch (e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* updateUserAddress(action) {
  const res = yield call(daUtil.patch, `/admin-addresses/${action.address.id}/`, action.address);
  yield put({type: SUPPORT_USER_ADDRESS_UPDATED, data: res});
  yield put(Notifications.success({title: "Adresse" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));
}

function* loadDriverLogGroupInfo(action) {
  if(!action.order_id > 0){
    return;
  }
  try {
    const res = yield call(daUtil.get, `/driverloggroup-info/?order_id=${action.order_id}`);
    yield put({type: SUPPORT_ORDER_STAT_DRIVER_LOADED, data: res});
  } catch (e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* submitRedeliverOrder(action) {
  try {
    yield call(daUtil.post, `/redeliver-orders/`, action.data);
    yield put(Notifications.success({title: "Commande" , "message": "Nouvelle livraisson de commande créée",  autoDismiss:3, dismissible:"click"}));
    yield put({type: SUPPORT_MODAL_HIDE_ORDER_REDELIVER});
    yield put({type: SUPPORT_USER_LOAD_BY_ORDER,  order_id: action.data.order_id_to_redeliver});
  } catch (e) {
    let message = e.message;
    if (e.response && e.response.data && e.response.data.error) {
      message += " (" + e.response.data.error + ")";
    } else if (e.response && e.response.data && e.response.data.detail) {
      message += " (" + e.response.data.detail + ")";
    }
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* submitReprepareOrder(action) {
  if (action.data['products'].length === 0){
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": "Aucun produit sélectionné",  autoDismiss:5, dismissible:"click"}));
  } else {
    try {
      yield call(daUtil.post, `/duplicate-orders/`, action.data);
      yield put(Notifications.success({title: "Commande" , "message": "Repréparation de commande effectuée",  autoDismiss:3, dismissible:"click"}));
      yield put({type: SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER});
      yield put({type: SUPPORT_USER_LOAD_BY_ORDER,  order_id: action.data.order_id_to_duplicate});
    } catch (e) {
      let message = e.message;
      if (e.response && e.response.data && e.response.data.error) {
        message += " (" + e.response.data.error + ")";
      }
      yield put(Notifications.error({title: "Une erreur est survenue" , "message": message,  autoDismiss:5, dismissible:"click"}));
      Raven.captureException(e);
    }
  }
}

function* changeOrderProductStatus(action) {
  let data = {
    'id': action.id,
    'new_status': action.status_code,
    'is_forced': 1
  };
  const res =  yield call(daUtil.patch, `/processing-orders/order_product_change_status/?ser=admin`, data);
  yield put({type: SUPPORT_ORDER_UPDATED, data: res});
  yield put(Notifications.success({title: "Commande" , "message": "La modification du statut du produit de la commande a été effectuée.",  autoDismiss:3, dismissible:"click"}));
}

function* updateOrder(action) {
  try {
    const res =  yield call(daUtil.patch, `/processing-orders/${action.orderId}/?ser=admin`, action.toUpdate);
    yield put({type: SUPPORT_ORDER_UPDATED, data: res});
    if (typeof(action.opzPatchs) !== "undefined") {
      yield action.opzPatchs.map(opzPatch => {
        let opz_action = {orderId: action.orderId, prepZone: opzPatch};
        return put({...opz_action, type: SUPPORT_ORDER_PREPZONE_UPDATE});
      });
    }
    yield put(Notifications.success({title: "Commande" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));
  } catch(e) {
    yield put(errorThrown(e));
  }
}

function* addOrderCrate({ orderId, crateCode }) {
  yield put(Notifications.info({title: "Commande" , "message": "Ajout en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const order =  yield call(api.addOrderCrate, orderId, crateCode);
    yield put({type: SUPPORT_ORDER_CRATE_UPDATED, crates: order.crates});
    yield put(Notifications.success({title: "Commande" , "message": "Cagette ajoutée",  autoDismiss:3, dismissible:"click"}));
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put(Notifications.error({title: "Commande" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    } else {
      yield put(Notifications.error({title: "Commande" , "message": "Impossible d'ajouter la cagette",  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* deleteOrderCrate({ orderId, crateCode }) {
  yield put(Notifications.info({title: "Commande" , "message": "Suppression en cours...",  autoDismiss:3, dismissible:"click"}));
  try {
    const order =  yield call(api.deleteOrderCrate, orderId, crateCode);
    yield put({type: SUPPORT_ORDER_CRATE_UPDATED, crates: order.crates});
    yield put(Notifications.success({title: "Commande" , "message": "Cagette supprimée",  autoDismiss:3, dismissible:"click"}));
  } catch(e) {
    Raven.captureException(e);
    if (e.response && e.response.data) {
      yield put(Notifications.error({title: "Commande" , "message": `Erreur: ${e.response.data.error}`,  autoDismiss:3, dismissible:"click"}));
    } else {
      yield put(Notifications.error({title: "Commande" , "message": "Impossible de supprimer la cagette",  autoDismiss:3, dismissible:"click"}));
    }
  }
}

function* updateOrderAddress(action) {
  const res =  yield call(daUtil.patch, `/processing-orders/address/?ser=admin`, action.address);
  yield put({type: SUPPORT_ORDER_UPDATED, data: res});
  yield put(Notifications.success({title: "Adresse" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));
}
function* updateOrderPrepZone(action) {
  let res = null;
  try {
    res =  yield call(daUtil.post, `/processing-orders/${action.orderId}/pseudozone/?ser=admin`, action.prepZone);
    if (res.error) {
      if (res.error && res.error.message) {
        console.error('error', res.error);
        yield put(Notifications.error({title: "Une erreur est survenue" , "message": res.error.message,  autoDismiss:5, dismissible:"click"}));
      }
      return;
    }

    yield put({type: SUPPORT_ORDER_UPDATED, data: res});
    if (typeof(action.prepZone.transit_area_type) !== "undefined") {
      yield put({...action, type: TRANSIT_AREA_LOAD, order_id: action.orderId});
    }
    yield put(Notifications.success({title: "Prep de zone" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));

  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* updateOrderGiftMessage(action) {
  const res =  yield call(
    daUtil.patch,
    `/processing-orders/order_update_gift_message/?ser=admin`,
    {'gift_message': action.gift_message, 'order_id': action.orderId}
  );
  yield put({type: SUPPORT_ORDER_UPDATED, data: res});
  yield put(Notifications.success({title: "Message carte cadeau" , "message": "Sauvegarde effectuée",  autoDismiss:3, dismissible:"click"}));
}

function* orderRefund(action) {
  let data = {
    'amount': action.refundAmount,
    'subtype': action.subtype,
    'notes': action.notes,
    'quantity': action.quantity
  };
  data[action.field] = action.id;
  try {
    const res = yield call(daUtil.post, `/processing-orders/refund/?ser=admin`, data);
    yield put({type: SUPPORT_ORDER_UPDATED, data: res});
    yield put({type: SUPPORT_MODAL_HIDE_REFUND});
  } catch(e) {
    if (e.response && e.response.data && e.response.data.errors) {
      for (var i=0; i < e.response.data.errors.length; ++i) {
        let message = e.response.data.errors[i];
        yield put(Notifications.error({title: "Une erreur est survenue" , "message": message,  autoDismiss:5, dismissible:"click"}));
      }

    } else {
      yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    }
    Raven.captureException(e);
  }
}

function _invoiceDownload(order_id) {
  return da.request({
    method:'GET',
    url: `/orders/${order_id}/invoice/`,
    responseType: 'blob', // important
  }).then(response => response.data)
    .catch(err => {
      throw err;
    });
}

function* invoiceDownload(action) {
  const data = yield call(_invoiceDownload, action.order_id);

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', 'file.pdf');
  document.body.appendChild(link);
  link.click();
}

function _invoicesDownloadByYear(year, user_id) {
  return da.request({
    method: 'GET',
    url: `/orders/invoices/?year=${year}&user_id=${user_id}`,
  }).then(response => response.data)
    .catch(err => {
      if(err.response.status === 404){
        throw new Error("Aucune facture trouvée pour cette année");
      }
      throw err;
    });
}

function* invoicesDownloadByYear(action) {
  try {
    yield call(_invoicesDownloadByYear, action.year, action.user_id);
    yield put(Notifications.success({title: `Téléchargement des factures" , "message": "Une archive zip contenant les factures de l'année ${action.year} sera envoyée à l'email ${action.email} dans quelques instants (1 minute environ).`,  autoDismiss:5, dismissible:"click"}));
  } catch (e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
  }
}

function* onLoadStripeSync(action) {
  const res = yield call(daUtil.get, `/stripesync/?user_id=${action.user_id}`);
  yield put({...res, type: SUPPORT_STRIPESYNC_LOADED});
}


function* onLoadPaygreenSync(action) {
  const datas = yield call(daUtil.get, `/paygreen/transactions?user_id=${action.user_id}`);
  yield put({datas, type: SUPPORT_PAYGREENSYNC_LOADED});
}

function* onLoadCreditSync(action) {
  try {
    const res = yield call(daUtil.get, `/users/${action.user_id}/credits`);
    yield put({credit_datas: res, type: SUPPORT_CREDITSYNC_LOADED});
  } catch (e) {
    console.log('Error code: not allowed to load credits', e.response.status);
  }
}

function* onReloadDepositUser(action) {
  yield put({...action, type: SUPPORT_DEPOSIT_USER_HISTORY_LOAD});
  yield put({...action, type: SUPPORT_DEPOSIT_USER_CURRENT_LOAD});
}

function* onLoadDepositUserHistory(action) {
  try {
    let offset = 0;
    if (typeof(action.offset) !== 'undefined') {
      offset = action.offset;
    }
    const res_history = yield call(daUtil.get, `/deposituser/history/?user_id=${action.user_id}&offset=${offset}`);
    yield put({...res_history, type: SUPPORT_DEPOSIT_USER_HISTORY_LOADED});
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}
function* onLoadDepositUserCurrent(action) {
  try {
    const res_current = yield call(daUtil.get, `/deposituser/current/?user_id=${action.user_id}`);
    yield put({...res_current, type: SUPPORT_DEPOSIT_USER_CURRENT_LOADED});
  } catch(e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}
function* onDriverDepositCreateReceived(action) {
  try {
    let datas = {
      'driver_id': action.driver_id,
      'user_id': action.user_id,
      'product_id': action.product_id,
      'quantity': action.quantity,
    };
    yield call(daUtil.post, '/depositdriver/receive_from/', datas);
    yield put(Notifications.success({title: "Consigne Livreur" , "message": "Remise de produit effectuée!",  autoDismiss:3, dismissible:"click"}));
  } catch(error) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": error.message,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(error);
  }
  yield put({...action, type: SUPPORT_DEPOSIT_RELOAD});
}

function* loadActivitiesLogs(action) {
  const res = yield call(daUtil.get, `/activitieslogs/?order_id=${action.order_id}`);
  yield put({...res, type: SUPPORT_ACTIVITIESLOGS_LOADED});
}

function* loadDriverList() {
  const res = yield call(daUtil.get, `/drivers/?order_name=1&has_recent_activity=1`);
  yield put({...res, type: SUPPORT_DRIVER_LIST_LOADED});
}

function* deleteAccount(action) {
  try {
    const res = yield call(daUtil.post, '/users/' + action.userId + '/anonymise/');
    yield put(Notifications.success({title: "Succès" , "message": "Utilisateur anonymisé avec succès",  autoDismiss:3, dismissible:"click"}));
    console.dir(res);
  }
  catch (e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.detail,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}

function* cancelSubscription(action) {
  try {
    const res = yield call(daUtil.post, '/stripe/subscriptions/' + action.subscriptionId + '/cancel/');
    //const res = yield call(daUtil.post, '/products/subscriptions_cancel/', {id: action.subscriptionId});
    yield put(Notifications.success({title: "Succès" , "message": "Abonnement annulé avec succès",  autoDismiss:3, dismissible:"click"}));
    console.dir(res);
    yield put({...action, type: SUPPORT_SUBSCRIPTION_CANCELED});
  }
  catch (e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.detail,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }
}


function* fixCenters(action) {
  try {
    const res = yield call(daUtil.post, '/users/' + action.userId + '/fix_centers/');
    yield put(
      Notifications.success(
        {
          title: "Succès",
          message: "Centres de l'utilisateur recalculés avec succès",
          autoDismiss: 3,
          dismissible: "click"
        }
      )
    );
    console.dir(res);
    yield* loadSupportByUser({user_id: action.userId});
  }
  catch (e) {
    yield put(
      Notifications.error(
        {
          title: "Une erreur est survenue",
          message: e.detail,
          autoDismiss: 5,
          dismissible: "click"
        }
      )
    );
    Raven.captureException(e);
  }
}

function* disconnectEdenred(action) {
  try {
    yield call(daUtil.post, '/me/cart/disable-edenred/', action);
    yield put(
      Notifications.success(
        {
          title: "Succès",
          message: "L'Edenred de l'utilisateur a bien été désactivé",
          autoDismiss: 3,
          dismissible: "click"
        }
      )
    );
  }
  catch (e) {
    yield put(
      Notifications.error(
        {
          title: "Une erreur est survenue",
          message: e.detail,
          autoDismiss: 5,
          dismissible: "click"
        }
      )
    );
    Raven.captureException(e);
  }
}


function* fetchDataForModal(action) {
  if(action.modalData.modalType === "address" && action.modalData.user_id > 0) {
    action.user_id = action.modalData.user_id;
    yield* loadUserAddresses(action);
  } else if (action.modalData.modalType === "order_stat_driver" && action.modalData.order && action.modalData.order.id > 0) {
    action.order_id = action.modalData.order.id;
    yield* loadDriverLogGroupInfo(action);
  }
}

function* editInternalNote(action) {
  try {
    const { id, content } = action
    const internalNote = yield call(daUtil.patch, `/support/internal-note/${id}/`, { content });
    yield put(Notifications.success({title: "Note interne" , "message": "Note interne sauvegardée",  autoDismiss:3, dismissible:"click"}));
    yield put({type: SUPPORT_USER_INTERNAL_NOTE_UPDATED, internalNote });
  }
  catch (e) {
    yield put(Notifications.error({title: "Une erreur est survenue" , "message": e.detail,  autoDismiss:5, dismissible:"click"}));
    Raven.captureException(e);
  }

}


export const supportSagas = [
  takeEvery(SUPPORT_ORDER_STATUS_UPDATE, changeStatusViaSupport),
  takeEvery(SUPPORT_USER_LOAD, loadSupportByUser),
  takeEvery(SUPPORT_USER_LOAD_BY_ORDER, loadSupportByOrder),
  takeEvery(SUPPORT_MODAL_VALIDATE_CREDIT, addCredits),
  takeEvery(SUPPORT_MODAL_VALIDATE_CREDIT_BULK, addCreditsBulk),
  takeEvery(SUPPORT_CANCEL_CREDIT, cancelCredit),
  takeEvery(SUPPORT_CANCEL_PAYGREEN_TRANSACTION, cancelPaygreenTransaction),
  takeEvery(SUPPORT_RECREDIT_SHIPPING_FEE, recreditShippingFee),
  takeEvery(SUPPORT_REFUND_SHIPPING_FEE, refundShippingFee),
  takeEvery(SUPPORT_MODAL_SHOW_SLOT, loadSlot),
  takeEvery(SUPPORT_MODAL_SHOW_ORDER_REDELIVER, loadRedeliver),
  takeEvery(SUPPORT_MODAL_SUBMIT_ORDER_REDELIVER, submitRedeliverOrder),
  takeEvery(SUPPORT_MODAL_SHOW_ORDER_REPREPARE_BEFORE, loadReprepareBefore),
  takeEvery(SUPPORT_MODAL_SHOW_ORDER_REPREPARE_AFTER, loadReprepareAfter),
  takeEvery(SUPPORT_MODAL_SUBMIT_ORDER_REPREPARE, submitReprepareOrder),
  takeEvery(SUPPORT_MODAL_VALIDATE_CHANGE_STATUS, changeOrderProductStatus),
  takeEvery(SUPPORT_ORDER_UPDATE, updateOrder),
  takeEvery(SUPPORT_ORDER_ADDRESS_UPDATE, updateOrderAddress),
  takeEvery(SUPPORT_ORDER_PREPZONE_UPDATE, updateOrderPrepZone),
  takeEvery(SUPPORT_ORDER_REFUND, orderRefund),
  takeEvery(SUPPORT_ORDER_CRATE_ADD, addOrderCrate),
  takeEvery(SUPPORT_ORDER_CRATE_DELETE, deleteOrderCrate),
  takeEvery(SUPPORT_USER_UPDATE, updateUser),
  takeEvery(SUPPORT_ORDER_GIFT_MESSAGE, updateOrderGiftMessage),
  takeEvery(SUPPORT_INVOICE_DOWNLOAD, invoiceDownload),
  takeEvery(SUPPORT_INVOICES_DOWNLOAD_BY_YEAR, invoicesDownloadByYear),
  takeEvery(SUPPORT_STRIPESYNC_LOAD, onLoadStripeSync),
  takeEvery(SUPPORT_PAYGREENSYNC_LOAD, onLoadPaygreenSync),
  takeEvery(SUPPORT_CREDITSYNC_LOAD, onLoadCreditSync),
  takeEvery(SUPPORT_DEPOSIT_USER_HISTORY_LOAD, onLoadDepositUserHistory),
  takeEvery(SUPPORT_DEPOSIT_USER_CURRENT_LOAD, onLoadDepositUserCurrent),
  takeEvery(SUPPORT_DEPOSIT_RELOAD, onReloadDepositUser),
  takeEvery(SUPPORT_DEPOSIT_USER_CREATE_RECEIVED, onDriverDepositCreateReceived),
  takeEvery(SUPPORT_ACTIVITIESLOGS_LOAD, loadActivitiesLogs),
  takeEvery(SUPPORT_DRIVER_LIST_LOAD, loadDriverList),
  takeEvery(SUPPORT_DELETE_ACCOUNT, deleteAccount),
  takeEvery(SUPPORT_FIX_CENTERS, fixCenters),
  takeEvery(SUPPORT_DISCONNECT_EDENRED, disconnectEdenred),
  takeEvery(SUPPORT_USER_ADDRESS_LOAD, loadUserAddresses),
  takeEvery(SUPPORT_USER_ADDRESS_UPDATE, updateUserAddress),
  takeEvery(SUPPORT_MODAL_SHOW, fetchDataForModal),
  takeEvery(SUPPORT_CANCEL_SUBSCRIPTION, cancelSubscription),
  takeEvery(SUPPORT_USER_EDIT_INTERNAL_NOTE, editInternalNote),
];
