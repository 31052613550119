import React, { useState } from 'react';
import moment from 'moment';
import { Alert, Button, Form } from 'react-bootstrap';

const UserInternalNote = ({ user, editNote }) => {
  const { internal_note: internalNote } = user;
  const [editing, setEditing] = useState(false);
  const [contentValue, setContentValue] = useState(internalNote.content);

  const saveContent = async () => {
    await editNote(internalNote.id, contentValue);
    setEditing(false);
  }

  return (
    <Alert variant="warning" className="col-md-5">
      <div style={{display: "flex", alignItems: "baseline"}}>
        <h4>Note</h4>
        {editing 
          ? <Button variant="link" onClick={() => setEditing(false)}>Annuler</Button>
          : <Button variant="link" onClick={() => setEditing(true)}>Modifier</Button>
        }
        {editing && <Button variant="link" onClick={() => saveContent(internalNote.id, contentValue)}>Enregistrer</Button>}
      </div>
      {editing ? (
        <div>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows={3}
            value={contentValue}
            onInput={(e) => setContentValue(e.target.value)}
          />
        </Form.Group>
        </div>
      ) : (
        <>
          <div>{internalNote.content}</div>
          <small>Par {internalNote.last_author.first_name} {internalNote.last_author.last_name}, {moment(internalNote.updated_at).format('DD/MM/YYYY HH:mm')}</small> 
        </>
      )
      }
    </Alert>
  );
}

export default UserInternalNote;