import React from 'react';
import { withFormik, Field } from 'formik';
import {Col, Form, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER, showPopinReprepareOrderBefore} from "../../../actions";
import {submitReprepareOrderAfter} from "../../../actions";
import { connect } from 'react-redux';
import moment from  'moment-timezone';
import {isRepreparationReasonCommentValid} from "../../../../../utils";

const buttonsSpaceStyle = {
  display: "flex",
  justifyContent: "space-around",
};

const MyForm = props => {
  const {
    values,
    handleSubmit,
    errors,
  } = props;

  let i = 0;
  return (
    <Form onSubmit={handleSubmit} className="form-horizontal">
      <FormGroup>
        <Field component="select" name="repreparation_reason_id" required={true} className="form-control">
          <option value="">-- Choisir la raison de la repréparation --</option>
          {
            values.repreparation_reasons
            ? Object.entries(values.repreparation_reasons.NEW_ORDER_AVAILABLE).sort(
                // Tri par valeur
                ([,a], [,b]) => a-b
              ).map(
                ([choice_id,], idx) => <option key={idx} value={choice_id}>
                  {values.repreparation_reasons.ID_TO_LABEL[choice_id]}
                </option>
              )
            : <option value="">Loading...</option>
          }
        </Field>
      </FormGroup>
      <FormGroup>
        <Form.Label>Commentaire de raison de repréparation</Form.Label>
        <Field
            component="textarea"
            col="100"
            row="9"
            name="repreparation_reason_comment"
            className="form-control"
        ></Field>
        {
          errors.repreparation_reason_comment
          && <div style={{color: 'red'}}>{errors.repreparation_reason_comment}</div>
        }
      </FormGroup>
      <FormGroup>
        <Field component="select" name="shipping_address_id" required={true} className="form-control" defaultValue={values.order.shipping_profile_address}>
          <option value="">-- Choisir l'adresse de livraison --</option>
          {values.addresses.loading === false 
            ? values.addresses.datas.map((address, idx) => 
              <option key={idx} value={address.id}>
                {address.street_number} {address.street}, {address.postal_code}, {address.city}
              </option>)
            :<option value="">Loading...</option>}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field component="select" name="slot" required={true} className="form-control">
          <option value="">-- Choisir le créneau de livraison --</option>
          {values.slots 
            ? values.slots.map( (s, idx) => <option key={idx} value={idx}>{s.text}</option>)
            :<option value="">Loading...</option>}
        </Field>
      </FormGroup>
      <FormGroup>
        <table className="table table-condensed table-hover">
          <tbody>
            <tr>
              <td>Quantité</td>
              <td>Nom</td>
              <td>Zone</td>
            </tr>
            {values.order.products && values.order.products.filter(product => product.checked).map(product => {
              const p = product.product;
              return (
                <tr key={product.product.id} id={"product_rep_" + i.toString()}>
                  <td><b>{product.reprepQuantity}</b> <i>/ {product.quantity}</i></td>
                  <td><a href={process.env.REACT_APP_PRODUCTS + `/products/${p.id}`} target="_blank" rel="noopener noreferrer" > {p.selling_name}</a></td>
                  <td id={"area_rep_" + i.toString()}>{p.area}.{p.shelf}.{p.tier}</td>
                </tr>
              )
            })}   
          </tbody>
        </table>
      </FormGroup>
      <FormGroup>
        <Col sm={12} style={buttonsSpaceStyle}> 
          <button type="button" onClick={() => {
            props.dispatch({"type": SUPPORT_MODAL_HIDE_ORDER_REPREPARE_AFTER});
            props.dispatch(showPopinReprepareOrderBefore(values.order));
          }} className="btn btn-danger">Retour</button>
          <button type="submit" className="btn btn-success" disabled={props.isSubmitting}>Valider</button>
        </Col>
      </FormGroup>
    </Form>
  );
};

MyForm.propTypes = {
  "values": PropTypes.any,
  "handleSubmit": PropTypes.any,
};

const ReprepareAfterForm = withFormik({
  handleSubmit(values, { props, setSubmitting }) {
    // Generate shipping_time and max_shipping_time
    let slot = values.slots[values.slot];
    let shipping_time = moment(slot.value, 'X').format();
    let max_shipping_time = moment(slot.max_delivery_time, 'X').format();

    // Generate products
    let products = [];
    Array.prototype.forEach.call(values.order.products, product => {
      if (product.checked){
        products.push({
          "product_id": product.product.id,
          "quantity": Math.round(product.reprepQuantity / product.product_pack)
        });
      }
    });

    // Prepare data for saga
    let data = {
      'order_id_to_duplicate': values.order.id,
      'shipping_time': shipping_time,
      'max_shipping_time': max_shipping_time,
      'products': products,
      'shipping_address_id': (
        typeof(values.shipping_address_id) === 'undefined'
          ? values.order.shipping_profile_address
          : values.shipping_address_id
      ),
      "repreparation_reason_id": parseInt(values.repreparation_reason_id, 10),
      "repreparation_reason_comment": values.repreparation_reason_comment,
    };
    props.dispatch(submitReprepareOrderAfter(data));
  },
  displayName: 'BasicForm',
  validate: (values, props) => {
    const errors = {};
    if (
      values.repreparation_reason_id !== ""
      && !isRepreparationReasonCommentValid(values.repreparation_reason_comment)
      && props.repreparation_reasons.MANDATORY_COMMENT[parseInt(values.repreparation_reason_id, 10)]
    ) {
      errors.repreparation_reason_comment = 'Un commentaire de raison de repréparation est requis.';
    }
    return errors;
  },
})(MyForm);
export const ReprepareAfterOrder = connect()(ReprepareAfterForm);
