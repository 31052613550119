import React from 'react';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import {SUPPORT_INVOICES_DOWNLOAD_BY_YEAR} from "../../actions";
import PropTypes from "prop-types";

class InternalInvoicesZone extends React.Component {


  render() {
    const { user,  invoicesDownloadByYear, authUser } = this.props;
    if (!user || !user.id) return <span></span>;

    const currentYear = new Date().getFullYear();
    const years = [currentYear, currentYear - 1, currentYear - 2];

    return (
      <React.Fragment>
        {years.map(year => (
          <Button
            key={year}
            style={{ marginTop: "10px" }}
            className='btn btn-primary d-block'
            onClick={() => invoicesDownloadByYear(year, user.id, authUser)}
          >
            Télécharger les factures {year}
          </Button>
        ))}
      </React.Fragment>
    );
  }
}

InternalInvoicesZone.propTypes = {
  "authUser": PropTypes.object,
  "user": PropTypes.object,
  "invoicesDownloadByYear": PropTypes.func
};

export const InvoicesZone = connect(
  (state) => {
    return {
      "authUser": state.user,
    };
  },
  (dispatch) => ({
    invoicesDownloadByYear: (year, user_id, authUser) => dispatch({ type: SUPPORT_INVOICES_DOWNLOAD_BY_YEAR, year, user_id: user_id, email: authUser.email  })
  })
)(InternalInvoicesZone);
