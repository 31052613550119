import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Table} from "react-bootstrap";

import moment from "moment/moment";
import {Address} from "../../../components/Common";

import "../css/OrderDeliveryDetail.css";
import {showPopinOrderAddress, showPopinOrderAddressName, SUPPORT_INVOICE_DOWNLOAD} from "../actions";




const InternalOrderInvoice = ({
  order,
  popinAddress,
  popinAddressName,
  invoiceDownload,
  readOnly,
}) => {
  if (!order) {
    return null;
  }
  moment.locale('fr');


  return <Table size="sm" hover className="OrderDeliveryDetail">
    <tbody>
      <tr><th colSpan={2}>Facturation</th></tr>
      <tr>
        <td>{order.billing_address.company} {order.billing_address.firstname} {order.billing_address.lastname}</td>
        <td><Button variant="primary" size="xs"  onClick={() => popinAddressName(order.billing_address) } disabled={readOnly || order.updating}>Changer nom/prénom</Button></td>
      </tr>
      <tr>
        <td><Address address={order.billing_address} center_id={order.center}/></td>
        <td><Button variant="primary" size="xs"  onClick={() => popinAddress(order.billing_address) } disabled={readOnly || order.updating}>Changer</Button></td>
      </tr>
      <tr>
        <td>Facture</td>
        <td>
          <button className="btn btn-primary btn-xs" onClick={() => invoiceDownload(order.id)}>
            <span className="fa-solid fa-download"></span> Télécharger
          </button>
        </td>
      </tr>
    </tbody>
  </Table>;
};

InternalOrderInvoice.propTypes = {
  "order": PropTypes.object,
  "popinAddress": PropTypes.func,
  "popinAddressName": PropTypes.func,
  "invoiceDownload": PropTypes.func,
  readOnly: PropTypes.bool,
};

export const OrderInvoice = connect(
  null,
  (dispatch) => {
    return {
      "popinAddress": (address) => dispatch(showPopinOrderAddress(address, "Modifier l'adresse de facturation")),
      "popinAddressName": (address) => dispatch(showPopinOrderAddressName(address)),
      "invoiceDownload": (order_id) => dispatch({type: SUPPORT_INVOICE_DOWNLOAD, order_id: order_id}),
    };
  }
)(InternalOrderInvoice);
